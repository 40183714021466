.skeleton {
    background-color: white;
    /* margin: 0.75rem 0; */
    border-radius: 0.3rem;
    /* padding: 2rem 3rem 3rem; */

}

.skeleton.heading {
    height: 2rem;
    /* margin-bottom: 1rem; */
}

.skeleton.button {
    height: 2.5rem;
}

.skeleton.width-80 {
    width: 100%;
}

.skeleton.width-50 {
    width: 50%;
}

.skeleton.icon {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
}

.empty {
    height: 3rem;
}


@keyframes pulse {
    50% {
        opacity: .5;
    }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}